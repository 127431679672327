import React, { useState, useRef, useEffect } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus"
import { faMinus } from "@fortawesome/free-solid-svg-icons/faMinus"
import "./Accordion.scss"

const Accordion = ({ heading, startClosed, children, className }) => {
  const containerRef = useRef()

  const [containerHeight, setContainerHeight] = useState(0)
  const [isOpen, setIsOpen] = useState(startClosed ? false : true)

  useEffect(() => {
    if (containerRef.current && containerRef.current.scrollHeight && isOpen) {
      setContainerHeight(containerRef.current.scrollHeight + "px")
    }
  }, [])

  const toggleAccordion = () => {
    if (isOpen) {
      setContainerHeight(0)
    } else {
      setContainerHeight(containerRef.current.scrollHeight + "px")
    }
    setIsOpen(!isOpen)
  }

  const styles = {
    maxHeight: containerHeight
  }

  const mainClasses = classNames(
    "accordion",
    className,
    `${isOpen ? "accordion__open" : ""}`
  )
  return (
    <div className={mainClasses}>
      <div onClick={toggleAccordion} className="accordion__heading">
        <FontAwesomeIcon icon={isOpen ? faMinus : faPlus} color="#545454" />{" "}
        {heading}
      </div>

      <div
        className={isOpen ? "open" : "closed"}
        style={styles}
        ref={containerRef}>
        {children}
      </div>
    </div>
  )
}

export default Accordion
