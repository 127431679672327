import { graphql } from "gatsby"
import React, { useRef } from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import { Container, Section } from "../components/Layouts"
import WhatOurClientsSay from "../components/Services/clients"
import Contact from "../components/Services/contact"
import UpRightArrow from "../../static/images/up-right-arrow.svg"
import { Text, Image } from "../components/Core"
import Accordion from "../components/Accordian/Accordion"
import OurWorkPreview from "../components/Services/ourWorkPreview"
import MobileCarousel from "../components/Carousels/MobileCarousel"

function SingleService({ data }) {
  const post = data.allServicesJson.nodes[0]
  const { bodySection, faqSection } = post
  const { benefits } = bodySection

  return (
    <Layout
      navSpacer
      pageGradient
      className={`single-service dark`}
      theme="dark-teal">
      <SEO title={post.metaTitle} description={post.metaDescription} />

      <Section>
        <Container>
          <div className="ss-header">
            <div className="ss-header__content">
              <Text
                as="p"
                text={post.category}
                className="ss-header__category"
              />
              <Text
                as="h1"
                text={post.heading}
                className="ss-header__heading"
              />
              <div className="is-hidden-desktop ss-header__image">
                <Image useAR={false} publicId={post.heroImageMobile} />
              </div>
              <Text as="p" text={post.blurb} className="ss-header__blurb" />
            </div>
            <div className="ss-header__image is-hidden-touch">
              <Image useAR={false} publicId={post.heroImage} />
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <Container>
          <div className="ss-body-section">
            <Text as="h2" text={bodySection.heading} />
            <Text as="p" text={bodySection.blurb} />
            <div className="ss-body-section__blurb">
              <div className="is-hidden-touch">
                <UpRightArrow />
              </div>
              <Text as="p" text={bodySection.text} />
              <div className="is-hidden-desktop">
                <UpRightArrow />
              </div>
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <Container className="no-padding-touch">
          {/**DESKTOP VIEW */}
          <div className="ss-benefit is-hidden-touch">
            <Text as="h3" text={benefits.heading} />
            <div className="ss-benefit-grid">
              {benefits.benefitsList.map((benefit, index) => (
                <div
                  key={index}
                  className={`ss-benefit-content ${
                    benefits.benefitsList.length % 2 === 0 &&
                    index === benefits.benefitsList.length - 1
                      ? "last"
                      : ""
                  }`}>
                  <Text
                    text={index < 10 ? `0${index + 1}` : index + 1}
                    className="ss-benefit-content-number"
                  />
                  <Text as="h3" className="mb-1" text={benefit.heading} />
                  <Text text={benefit.text} />
                </div>
              ))}
            </div>
          </div>
          {/**MOBILE VIEW */}
          <div className="ss-benefit__slider is-hidden-desktop">
            <Text as="h3" text={benefits.heading} />
            <MobileCarousel>
              {benefits.benefitsList.map((benefit, index) => (
                <div key={index} className="ss-benefit__slider-card">
                  <Text
                    text={index < 10 ? `0${index + 1}` : index + 1}
                    className="ss-benefit-content-number"
                  />
                  <Text as="h3" text={benefit.heading} />
                  <Text text={benefit.text} />
                </div>
              ))}
            </MobileCarousel>
          </div>
        </Container>
      </Section>

      {faqSection.hasThisSection && (
        <Section className="ss-faq">
          <Container>
            <div className="ss-faq">
              <Text as="h4" text={faqSection.heading} />
              <div>
                {faqSection.faqList.map((faq, i) => (
                  <div key={i} className="ss-faq__item">
                    <Accordion
                      number={i + 1}
                      heading={faq.question}
                      startClosed>
                      <Text
                        useStringReplace
                        as="p"
                        text={faq.answer}
                        className="ss-faq__answer"
                      />
                    </Accordion>
                  </div>
                ))}
              </div>
            </div>
          </Container>
        </Section>
      )}

      <OurWorkPreview />

      <Section>
        <Container>
          <WhatOurClientsSay className="dark-theme" />
        </Container>
      </Section>

      <Section zeroBottom>
        <Container>
          <Contact className="dark-theme" arrow={false} />
        </Container>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query singleServicePage($title: String!) {
    allServicesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        category
        blurb
        heroImage
        heroImageMobile
        faqSection {
          hasThisSection
          heading
          faqList {
            question
            answer
          }
        }
        bodySection {
          heading
          blurb
          text
          benefits {
            heading
            benefitsList {
              heading
              text
            }
          }
        }
      }
    }
  }
`

export default SingleService
